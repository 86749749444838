/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlatformEnum } from '../models/PlatformEnum';
import type { PublisherListResponse } from '../models/PublisherListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublisherService {

    /**
     * This is a list publishers method.
     * This method returns a list of publishers and their supported events that the application currently supports.
     * @param platform The platform for which publishers and supported events are requested.
     * @returns PublisherListResponse OK
     * @throws ApiError
     */
    public static getPublishers(
        platform: PlatformEnum,
    ): CancelablePromise<PublisherListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/publishers',
            query: {
                'platform': platform,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
