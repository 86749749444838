import {
  PopoverProps,
  Popover,
  PopoverTrigger,
  PopoverSurface
} from '@fluentui/react-components';

import './InfoPopover.css';

interface InfoPopoverProps extends Partial<PopoverProps> {
  readonly surface: string;
}

export const InfoPopover = (props: InfoPopoverProps) => (
  <Popover {...props} withArrow mouseLeaveDelay={0}>
    <PopoverTrigger>
      <i className="ms-Icon ms-Icon--Info" aria-hidden="true"></i>
    </PopoverTrigger>

    <PopoverSurface>{props.surface}</PopoverSurface>
  </Popover>
);
