import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner
} from '@fluentui/react-components';

interface ModalProps {
  readonly title: string;
  readonly content: string;
  readonly isOpen: boolean;
  readonly isLoading: boolean;
  readonly hideModal: () => void;
  readonly handleConfirmation: () => void;
}

export const Modal = ({
  title,
  content,
  isOpen,
  isLoading,
  hideModal,
  handleConfirmation
}: ModalProps) => {
  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{content}</DialogContent>
          <DialogActions>
            <Button onClick={hideModal}>Cancel</Button>
            <Button disabled={isLoading} onClick={handleConfirmation} appearance="primary">
              {
                isLoading
                  ? <Spinner size='extra-tiny' label={'Deleting'} />
                  : 'Confirm'
              }
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
