import {
  Button,
  TableColumnDefinition,
  TableCellLayout,
  createTableColumn,
  Tooltip
} from '@fluentui/react-components';
import { EditRegular, DeleteRegular, Info24Regular } from '@fluentui/react-icons';

import { SubscriptionView } from 'openapi';
import { SubscriptionInfoTooltip } from 'components/SubscriptionInfoTooltip/SubscriptionInfoTooltip';

type Handler = (id: number, projectId: number) => void;

export const createWebhooksColumns = (
  editHandler: Handler,
  deleteHandler: Handler,
  isDeleting: boolean
) => {


  const columns: TableColumnDefinition<SubscriptionView>[] = [
    {
      columnId: 'id',
      renderHeaderCell: () => {
        return 'ID';
      },
      compare: (a: SubscriptionView, b: SubscriptionView) =>
        (a.id as number) - (b.id as number),
      renderCell: (item: SubscriptionView) => (
        <TableCellLayout truncate={true}>{item.id}</TableCellLayout>
      )
    },
    {
      columnId: 'projectName',
      renderHeaderCell: () => {
        return 'Project name';
      },
      compare: (a: SubscriptionView, b: SubscriptionView) =>
        (a.project?.name as string).localeCompare(b.project?.name as string),
      renderCell: (item: SubscriptionView) => (
        <TableCellLayout truncate={true}>{item.project?.name}</TableCellLayout>
      )
    },
    {
      columnId: 'eventType',
      renderHeaderCell: () => {
        return 'Event';
      },
      compare: (a: SubscriptionView, b: SubscriptionView) =>
        (a.eventName as string).localeCompare(b.eventName as string),
      renderCell: (item: SubscriptionView) => (
        <TableCellLayout truncate={true}>{item.eventName}</TableCellLayout>
      )
    },
    {
      columnId: 'messageContent',
      renderHeaderCell: () => {
        return 'Thread';
      },
      compare: (a: SubscriptionView, b: SubscriptionView) =>
        (a.messageContent as string).localeCompare(b.messageContent as string),
      renderCell: (item: SubscriptionView) => (
        <TableCellLayout truncate={true}>{item.messageContent}</TableCellLayout>
      )
    },
    {
      columnId: 'modifiedBy',
      renderHeaderCell: () => {
        return 'Last modified by';
      },
      compare: (a: SubscriptionView, b: SubscriptionView) =>
        (a.modifiedBy as string).localeCompare(b.modifiedBy as string),
      renderCell: (item: SubscriptionView) => (
        <TableCellLayout truncate={true}>{item.modifiedBy}</TableCellLayout>
      )
    },
    createTableColumn({
      columnId: 'actions',
      renderHeaderCell: () => {
        return 'Actions';
      },
      renderCell: (item) => {
        return (
          <>
            <SubscriptionInfoTooltip id="subscriptionInfoTooltip" subscription={item} />
            <Button
              className='action-button'
              onClick={() =>
                editHandler(item.id as number, item.project?.id as number)
              }
              aria-label="Edit"
              icon={<EditRegular />}
            />
            <Button
              disabled={isDeleting}
              className='action-button'
              aria-label="Delete"
              icon={<DeleteRegular />}
              onClick={() =>
                deleteHandler(item.id as number, item.project?.id as number)
              }
            />
          </>
        );
      }
    })
  ];

  return columns;
};
