// EventDropdown.tsx
import React from 'react';
import { Dropdown, Option, OptionGroup } from '@fluentui/react-components';
import { Publisher } from 'openapi'; // Adjust if necessary based on your actual imports
import { InfoPopoverLabel } from 'components/InfoPopoverLabel/InfoPopoverLabel'; // Adjust the import path as necessary
import { LoadingWrapper } from 'components/LoadingWrapper/LoadingWrapper'; // Adjust the import path as necessary

interface EventDropdownProps {
  publishers: Publisher[];
  selectedEvent: string[];
  onEventSelected: (event: any, data: any) => void;
  isEditMode: boolean;
  globalLoading: boolean;
  eventSelectedLoading: boolean;
  value: string;
}

export const EventDropdown: React.FC<EventDropdownProps> = ({
  publishers,
  selectedEvent,
  onEventSelected,
  isEditMode,
  globalLoading,
  eventSelectedLoading,
  value
}) => {
  return (
    <div className="dropdown-wrapper">
      <InfoPopoverLabel
        id="events-dropdown"
        className="dropdown-label"
        label="Select an event*"
        surface="Select an event to trigger on and configure any filters"
      />

      <LoadingWrapper isLoading={eventSelectedLoading}>
        <Dropdown
          aria-labelledby="events-dropdown"
          appearance="underline"
          selectedOptions={selectedEvent}
          onOptionSelect={onEventSelected}
          value={value}
          disabled={globalLoading || isEditMode}
        >
          {publishers.map((publisher: Publisher) => (
            <OptionGroup
              key={publisher.id}
              className="publisher-option-group"
              label={<InfoPopoverLabel label={publisher.id as string} surface={publisher.description as string} />}
            >
              {publisher.supportedEvents?.map((event) => (
                <Option key={event.id} value={event.id as unknown as string}>
                  {event.name as string}
                </Option>
              ))}
            </OptionGroup>
          ))}
        </Dropdown>
      </LoadingWrapper>
    </div>
  );
};
