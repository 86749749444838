import { useCallback, useState } from 'react';

export type DropdownOption = {
  id: string;
  name: string;
};

export const useDropdown = (initialValue?: string[]) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>(
    initialValue || []
  );

  const handleDropdownSelect = (_: any, data: any) => {
    setSelectedOptions(data.selectedOptions);
  };

  const resetDropdown = useCallback(() => {
    setSelectedOptions([]);
  }, []);

  return {
    selectedOptions,
    onOptionSelect: handleDropdownSelect,
    resetDropdown
  };
};
