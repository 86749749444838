import { OpenAPI } from 'openapi';
import * as microsoftTeams from '@microsoft/teams-js';

export class AuthenticationService {
  public static async login() {
    try {
      const token = await microsoftTeams.authentication.getAuthToken();

      OpenAPI.TOKEN = token;
    } catch (error) {
      throw new Error('Login failed');
    }
  }

  private static decodeJWT(token: string) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );

      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error decoding the token:', error);
      return null;
    }
  }

  public static isTokenExpired(token: string): boolean {
    const decodedToken = this.decodeJWT(token);

    if (!decodedToken || !decodedToken.exp) {
      return true;
    }

    const currentDate = new Date();
    const expirationDate = new Date(decodedToken.exp * 1000);

    return currentDate > expirationDate;
  }
}
