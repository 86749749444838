import { useEffect, useMemo, useCallback, useState } from 'react';
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  tokens
} from '@fluentui/react-components';
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import { app } from '@microsoft/teams-js';
import { useTeamsUserCredential } from '@microsoft/teamsfx-react';
import { ToastContainer } from 'react-toastify';

import { ChannelService, OpenAPI } from 'openapi';

import { CreateWebhook } from 'pages/create-webhook/CreateWebhook';
import { TabConfig } from 'pages/TabConfig/TabConfig';
import { WebhookListing } from 'pages/webhook-listing/WebhookListing';

import { config } from 'utils/constants/configurations';
import {
  ROUTE_CONFIG,
  ROUTE_WEBHOOKS_CREATE,
  ROUTE_WEBHOOKS_LISTING
} from 'utils/constants/routes';

import { TeamsFxContext } from 'utils/contexts/TeamsFxContext';

import { Theme } from 'utils/enums/Theme';

import { AuthenticationService } from 'services/Authentication';

import 'services/http';

OpenAPI.BASE = process.env.REACT_APP_SERVICE_URL as string;

export const App = () => {
  // States
  const [isAuthorized, setIsAuthorized] = useState(false);

  const { context, loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!
    });

  // Variables
  const channelId = useMemo(() => {
    return context?.channel?.id || '';
  }, [context]);

  // Render values
  const themeClassname = useMemo(
    () =>
      themeString === Theme.Default
        ? Theme.Light
        : themeString === Theme.Dark
        ? Theme.Dark
        : Theme.Contrast,
    [themeString]
  );

  // Service calls
  const authorize = useCallback(async () => {
    try {
      await AuthenticationService.login();

      setIsAuthorized(true);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const initializeChannel = useCallback(async () => {
    try {
      await ChannelService.registerChannel(channelId);
    } catch (error) {
      console.error(error);
    }
  }, [channelId]);

  // Use effects
  useEffect(() => {
    loading &&
      app.initialize().then(() => {
        app.notifySuccess();
      });
  }, [loading]);

  useEffect(() => {
    if (isAuthorized && channelId) {
      initializeChannel();
    }
  }, [channelId, initializeChannel, isAuthorized]);

  useEffect(() => {
    authorize();
  }, [authorize]);

  return (
    <TeamsFxContext.Provider
      value={{ theme, themeString, teamsUserCredential, context }}
    >
      <FluentProvider
        theme={
          themeString === Theme.Dark
            ? teamsDarkTheme
            : themeString === Theme.Contrast
            ? teamsHighContrastTheme
            : {
                ...teamsLightTheme,
                colorNeutralBackground3: '#eeeeee'
              }
        }
        style={{ background: tokens.colorNeutralBackground3 }}
      >
        {isAuthorized && channelId && (
          <div className={themeClassname}>
            <div className="tab-container page-padding">
              <ToastContainer
                autoClose={3000}
                closeOnClick={false}
                theme="colored"
              />

              <Router>
                {!loading && (
                  <Routes>
                    <Route
                      path={ROUTE_WEBHOOKS_LISTING}
                      element={<WebhookListing />}
                    />
                    <Route
                      path={ROUTE_WEBHOOKS_CREATE}
                      element={<CreateWebhook />}
                    />
                    <Route path={ROUTE_CONFIG} element={<TabConfig />} />
                    <Route
                      path="*"
                      element={<Navigate to={ROUTE_WEBHOOKS_LISTING} />}
                    ></Route>
                  </Routes>
                )}
              </Router>
            </div>
          </div>
        )}
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
};
