import { app, pages } from '@microsoft/teams-js';
import { useContext, useMemo } from 'react';

import { TeamsFxContext } from 'utils/contexts/TeamsFxContext';
import { Theme } from 'utils/enums/Theme';

/**
 * The 'Config' component is used to display your group tabs
 * user configuration options.  Here you will allow the user to
 * make their choices and once they are done you will need to validate
 * their choices and communicate that to Teams to enable the save button.
 */
export const TabConfig = () => {
  const { themeString } = useContext(TeamsFxContext);

  // Initialize the Microsoft Teams SDK
  app.initialize().then(() => {
    /**
     * When the user clicks "Save", save the url for your configured tab.
     * This allows for the addition of query string parameters based on
     * the settings selected by the user.
     */
    pages.config.registerOnSaveHandler((saveEvent) => {
      const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
      pages.config
        .setConfig({
          suggestedDisplayName: 'PipePulse',
          entityId: 'Test',
          contentUrl: baseUrl + '/index.html#/tab',
          websiteUrl: baseUrl + '/index.html#/tab'
        })
        .then(() => {
          saveEvent.notifySuccess();
        });
    });

    /**
     * After verifying that the settings for your tab are correctly
     * filled in by the user you need to set the state of the dialog
     * to be valid.  This will enable the save button in the configuration
     * dialog.
     */
    pages.config.setValidityState(true);
  });

  const themeClassname = useMemo(
    () =>
      themeString === Theme.Default
        ? Theme.Light
        : themeString === Theme.Dark
        ? Theme.Dark
        : Theme.Contrast,
    [themeString]
  );

  return (
    <div
      style={{
        height: '100%',
        padding: '0.5rem 1rem'
      }}
      className={themeClassname}
    >
      <h1>Tab Configuration</h1>
      <div>
        This is where you will add your tab configuration options the user can
        choose when the tab is added to your team/group chat.
      </div>
    </div>
  );
};
