import { useCallback, useState } from 'react';

import { StringArrayDictionary, isAnyValue } from 'utils/interfaces/Common';
import { DESCRIPTOR_ID_BUILD_STATUS, DESCRIPTOR_ID_STATUS_CHANGE } from '../constants/common';

export type DropdownOption = {
  id: string;
  name: string;
};

export const useMultipleDropdowns = (initialValues?: StringArrayDictionary) => {
  const [selectedOptions, setSelectedOptions] = useState<StringArrayDictionary>(
    initialValues || {}
  );

  const updateSelectedOptions = (descriptorId: string, selectedOptions: string[]) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [descriptorId]: selectedOptions
    }));
  }

  const handleDropdownSelect = (descriptorId: string) => (_: any, data: any) => {
    //if the build status is selected, then the status change should be reset depending on the value of the build status
    if (descriptorId === DESCRIPTOR_ID_BUILD_STATUS && !isAnyValue(data.selectedOptions[0])) {
      updateSelectedOptions(DESCRIPTOR_ID_BUILD_STATUS, data.selectedOptions);
      updateSelectedOptions(DESCRIPTOR_ID_STATUS_CHANGE, ['false']);
    } else if (descriptorId === DESCRIPTOR_ID_STATUS_CHANGE && !data.selectedOptions.length) { //initialize status change with default false
      updateSelectedOptions(descriptorId, ['false']);
    } else {
      updateSelectedOptions(descriptorId, data.selectedOptions);
    }
  };

  const handleAllOptionsSelect = useCallback(
    (options: any) => {
      setSelectedOptions(options);
    },
    [setSelectedOptions]
  );

  const resetDropdowns = useCallback(() => {
    setSelectedOptions({});
  }, [setSelectedOptions]);

  return {
    selectedOptions,
    onOptionSelect: handleDropdownSelect,
    onAllOptionsSelect: handleAllOptionsSelect,
    resetDropdowns
  };
};
