// src/hooks/usePublishers.ts
import { useState, useEffect, useCallback } from 'react';
import { PublisherService, Publisher, Project, PlatformEnum } from 'openapi'; // Adjust imports as necessary

interface UsePublishersResult {
  publishers: Publisher[];
  loading: boolean;
  error: Error | null;
}

export const usePublishers = (selectedProject: Project | undefined, eventSelect: (_: any, data: any) => void): UsePublishersResult => {
  const [publishers, setPublishers] = useState<Publisher[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchPublishers = useCallback(async () => {
    if (!selectedProject) return;

    setLoading(true);
    try {
      const response = await PublisherService.getPublishers(selectedProject?.platform as PlatformEnum);
      if (response.data) {
        let publishers = response.data.publishers;
        setPublishers(publishers || []);

        //if a single event, select it
        if (publishers?.length === 1 && publishers[0].supportedEvents?.length === 1) {
          const data = {
            selectedOptions: [
              publishers[0].supportedEvents[0].id
            ]
          };
          eventSelect(null, data);
        }
      }
      setError(null);
    } catch (e) {
      setError(e as Error);
      setPublishers([]);
    } finally {
      setLoading(false);
    }
  }, [selectedProject]);

  useEffect(() => {
    fetchPublishers();
  }, [fetchPublishers]);

  return { publishers, loading, error };
};
