/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessageListResponse } from '../models/MessageListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChannelService {

    /**
     * This method registers a new channel.
     * This method register a new channel based on the given id. Retrieves and persists it's team and channel information.
     * @param channelId The channel ID.
     * @returns any OK
     * @throws ApiError
     */
    public static registerChannel(
        channelId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/channels/{channelId}',
            path: {
                'channelId': channelId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This is a list messages method.
     * This method returns a list of messages for the given team and channel.
     * @param channelId The channel ID.
     * @returns MessageListResponse OK
     * @throws ApiError
     */
    public static getChannelMessages(
        channelId: string,
    ): CancelablePromise<MessageListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/channels/{channelId}/messages',
            path: {
                'channelId': channelId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
