import { SubscriptionView } from 'openapi';
import { Button, Table, TableBody, TableCell, TableHeader, TableRow, Tooltip, makeStyles, mergeClasses } from '@fluentui/react-components';
import { Info20Regular } from '@fluentui/react-icons';

type TooltipProps = {
  id: string;
  subscription: SubscriptionView;
};

const tooltipStyles = makeStyles({

  tooltipButton: {
    maxWidth: '32px', 
    minWidth: '32px', 
    paddingLeft: '5px', 
    paddingRight: '5px', 
    paddingTop: '5px', 
    paddingBottom: '5px', 
    marginRight: '5px', 
    minHeight: '32px',
  },
  tooltipContent: {
    maxWidth: '350px',
    tableLayout: 'auto',
    fontSize: '14px', 
    paddingRight: '15px',
    paddingBottom: '15px',
  },
});




export const SubscriptionInfoTooltip: React.FC<TooltipProps> = ({ id, subscription }) => {

  const classes = tooltipStyles();

  const tooltipContent = (
    <div>
      <h4 style={{paddingLeft: '7px'}}>{subscription.eventName}</h4>
      <Table style={{tableLayout: 'auto' }}>
        <TableBody>
          {subscription.inputValues?.map((inputValue, index) => (
            <TableRow key={index} style={{whiteSpace: 'nowrap', height: '20px', borderBottom: 'none', fontSize: '12px'}}>
              <TableCell style={{height: '15px', fontWeight: 'bold'}}>{inputValue.id}:</TableCell>
              <TableCell style={{height: '15px'}}>
                {inputValue.value?.displayValue}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );

  return (<Tooltip content={{children: tooltipContent, className: classes.tooltipContent}}
    relationship="label" 
   positioning='before-top'
   hideDelay={0}
   showDelay={0}
   withArrow={true} 
   >
   <Button className={classes.tooltipButton}
     aria-label="Info">
     <Info20Regular />
   </Button>
 </Tooltip>);
};
