/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InputValuesQuery } from '../models/InputValuesQuery';
import type { InputValuesResponse } from '../models/InputValuesResponse';
import type { SubscriptionCreate } from '../models/SubscriptionCreate';
import type { SubscriptionListResponse } from '../models/SubscriptionListResponse';
import type { SubscriptionResponse } from '../models/SubscriptionResponse';
import type { SubscriptionUpdate } from '../models/SubscriptionUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionService {

    /**
     * This is a create subscription method.
     * This method creates a new subscription/webhook. A Subscription object must be provided in the request body.
     * @param channelId The channel ID.
     * @param projectId The project ID.
     * @param requestBody
     * @returns any CREATED
     * @throws ApiError
     */
    public static createSubscription(
        channelId: string,
        projectId: number,
        requestBody: SubscriptionCreate,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/channels/{channelId}/projects/{projectId}/subscriptions',
            path: {
                'channelId': channelId,
                'projectId': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This is a get subscription method.
     * This method returns a subscription/webhook by id.
     * @param channelId The channel ID.
     * @param projectId The project ID.
     * @param subscriptionId The id of the subscription to get.
     * @returns SubscriptionResponse OK
     * @throws ApiError
     */
    public static getSubscription(
        channelId: string,
        projectId: number,
        subscriptionId: number,
    ): CancelablePromise<SubscriptionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/channels/{channelId}/projects/{projectId}/subscriptions/{subscriptionId}',
            path: {
                'channelId': channelId,
                'projectId': projectId,
                'subscriptionId': subscriptionId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This is an update subscription method.
     * This method updates a subscription/webhook by id. A Subscription object must be provided in the request body.
     * @param channelId The channel ID.
     * @param projectId The project ID.
     * @param subscriptionId The id of the subscription to get.
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateSubscription(
        channelId: string,
        projectId: number,
        subscriptionId: number,
        requestBody: SubscriptionUpdate,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/channels/{channelId}/projects/{projectId}/subscriptions/{subscriptionId}',
            path: {
                'channelId': channelId,
                'projectId': projectId,
                'subscriptionId': subscriptionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This is a delete subscription method.
     * This method deletes a subscription/webhook by id.
     * @param channelId The channel ID.
     * @param projectId The project ID.
     * @param subscriptionId The id of the subscription to get.
     * @returns void
     * @throws ApiError
     */
    public static deleteSubscription(
        channelId: string,
        projectId: number,
        subscriptionId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/channels/{channelId}/projects/{projectId}/subscriptions/{subscriptionId}',
            path: {
                'channelId': channelId,
                'projectId': projectId,
                'subscriptionId': subscriptionId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This is a list of supported event possible input values method.
     * This method returns a list of possible input values for the given supported event, e.g. a list of pipelines for a pipeline event. An InputValuesQuery object must be provided in the request body.
     * @param channelId The channel ID.
     * @param projectId The project ID.
     * @param requestBody
     * @returns InputValuesResponse OK
     * @throws ApiError
     */
    public static getInputValues(
        channelId: string,
        projectId: number,
        requestBody: InputValuesQuery,
    ): CancelablePromise<InputValuesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/channels/{channelId}/projects/{projectId}/subscriptions/inputValuesQuery',
            path: {
                'channelId': channelId,
                'projectId': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This is a list subscriptions method.
     * This method returns a list of subscriptions for the given channel.
     * @param channelId The channel ID.
     * @returns SubscriptionListResponse OK
     * @throws ApiError
     */
    public static getChannelSubscriptions(
        channelId: string,
    ): CancelablePromise<SubscriptionListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/channels/{channelId}/subscriptions',
            path: {
                'channelId': channelId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
