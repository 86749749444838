/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlatformListResponse } from '../models/PlatformListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectService {

    /**
     * This is a list projects method.
     * This method returns a list of projects that the requesting user's team has access to. Requesting user is determined by the JWT.
     * @param channelId The channel ID.
     * @returns PlatformListResponse OK
     * @throws ApiError
     */
    public static getTeamProjects(
        channelId: string,
    ): CancelablePromise<PlatformListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/channels/{channelId}/projects',
            path: {
                'channelId': channelId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
