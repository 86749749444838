// TriggerFilters.tsx
import { Dropdown, Input, Option, Spinner, Switch } from '@fluentui/react-components';
import { InfoPopoverLabel } from 'components/InfoPopoverLabel/InfoPopoverLabel';
import { LoadingWrapper } from 'components/LoadingWrapper/LoadingWrapper';
import { InputDescriptor, InputModeEnum, PossibleValue } from 'openapi';
import React from 'react';
import { DESCRIPTOR_ID_BUILD_STATUS, DESCRIPTOR_ID_STATUS_CHANGE } from 'utils/constants/common';
import { StringArrayDictionary } from 'utils/interfaces/Common';

interface TriggerFiltersProps {
  inputDescriptors: InputDescriptor[];
  selectedDescriptors: StringArrayDictionary;
  handleDescriptorsSelect: (id: string) => (event: any, data: any) => void;
  descriptorDependencies: StringArrayDictionary;
  globalLoading: boolean;
  isSaving: boolean;
  triggerFiltersLoading: boolean;
}

const DropdownInput = ({ descriptor, selected, handleDescriptorsSelect, isDisabled, isLoading }: {
  descriptor: InputDescriptor;
  selected: string[];
  handleDescriptorsSelect: (id: string) => (event: any, data: any) => void;
  isDisabled: boolean;
  isLoading: boolean;
}) => {
  let displayValue = '';

  if (selected) {
    const possibleValue = descriptor.values?.possibleValues?.find(
      (pv) => {
        return pv.value === selected[0];
      }
    );

    displayValue =
      possibleValue?.displayValue || possibleValue?.value || '';
  }

  return (
    <Dropdown
      selectedOptions={selected || []}
      onOptionSelect={handleDescriptorsSelect(descriptor.id!)}
      disabled={isDisabled || isLoading}
      value={displayValue}
      appearance="underline"
    >
      {descriptor.values?.possibleValues?.map((value: PossibleValue) => (
        <Option
          key={value.value}
          value={value.value}
          text={value.displayValue}>
          {(value.displayValue as string) ||
            (value.value as string)}
        </Option>
      ))}
    </Dropdown>
  )
};

const CheckboxInput = ({ descriptor, selected, handleDescriptorsSelect, isDisabled, isLoading, buildStatusToggleDisabled }: {
  descriptor: InputDescriptor;
  selected: string[];
  handleDescriptorsSelect: (id: string) => (event: any, data: any) => void;
  isDisabled: boolean;
  isLoading: boolean;
  buildStatusToggleDisabled: boolean;
}) => (
  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
    <Switch
      checked={selected?.[0] === 'true' && !buildStatusToggleDisabled}
      onChange={(event) => handleDescriptorsSelect(descriptor.id!)(null, { selectedOptions: [event.target.checked.toString()] })}
      disabled={isDisabled || buildStatusToggleDisabled || isLoading}
    />
  </div>
);

const TextInput = ({ descriptor, selected, handleDescriptorsSelect, isDisabled, isLoading }: {
  descriptor: InputDescriptor;
  selected: string[];
  handleDescriptorsSelect: (id: string) => (event: any, data: any) => void;
  isDisabled: boolean;
  isLoading: boolean;
}) => (
  <Input style={{ width: '50%' }}
    value={selected?.[0] || ''}
    onChange={(_, data) => handleDescriptorsSelect(descriptor.id!)(null, { selectedOptions: [data.value] })}
    disabled={isDisabled || isLoading}
    appearance="underline"
  />
);

const InputField = ({ descriptor, selectedDescriptors, handleDescriptorsSelect, descriptorDependencies, isLoading, isSaving }: {
  descriptor: InputDescriptor;
  selectedDescriptors: StringArrayDictionary;
  handleDescriptorsSelect: (id: string) => (event: any, data: any) => void;
  descriptorDependencies: StringArrayDictionary;
  isLoading: boolean;
  isSaving: boolean;
}) => {
  const selected = selectedDescriptors[descriptor.id!];
  const isDisabled = descriptorDependencies[descriptor.id!]?.some(
    (dependency) => !selectedDescriptors[dependency]?.length || selectedDescriptors[dependency]?.[0] === ''
  );
  const buildStatusToggleDisabled = descriptor.id === DESCRIPTOR_ID_STATUS_CHANGE &&
    !(selectedDescriptors[DESCRIPTOR_ID_BUILD_STATUS]?.[0] === '' || selectedDescriptors[DESCRIPTOR_ID_BUILD_STATUS]?.[0] === 'ANY');

  switch (descriptor.inputMode) {
    case InputModeEnum.DROPDOWN:
      return <DropdownInput descriptor={descriptor} selected={selected} handleDescriptorsSelect={handleDescriptorsSelect} isDisabled={isDisabled || isSaving} isLoading={isLoading} />;
    case InputModeEnum.CHECKBOX:
      return <CheckboxInput descriptor={descriptor} selected={selected} handleDescriptorsSelect={handleDescriptorsSelect} isDisabled={isDisabled || isSaving} isLoading={isLoading} buildStatusToggleDisabled={buildStatusToggleDisabled} />;
    case InputModeEnum.TEXT:
      return <TextInput descriptor={descriptor} selected={selected} handleDescriptorsSelect={handleDescriptorsSelect} isDisabled={isDisabled || isSaving} isLoading={isLoading} />;
    default:
      return <div>Unsupported input mode</div>;
  }
};

export const TriggerFilters: React.FC<TriggerFiltersProps> = ({
  inputDescriptors,
  selectedDescriptors,
  handleDescriptorsSelect,
  descriptorDependencies,
  globalLoading,
  isSaving,
  triggerFiltersLoading
}) => (
  <>
    {inputDescriptors.length === 0 && triggerFiltersLoading ? (
      <div className='filters-loading'>
        <h3>Trigger Filters</h3>
        <Spinner className='spinner' size='extra-tiny' />
      </div>
    ) : (
      <>
        {inputDescriptors.length > 0 && (<h3>Trigger Filters</h3>)}
        {inputDescriptors.map((descriptor) => (
          <div key={descriptor.id} className="dropdown-wrapper">
            <LoadingWrapper isLoading={globalLoading && !isSaving}>
              <InfoPopoverLabel
                className="dropdown-label"
                label={descriptor.name as string}
                surface={descriptor.description as string}
              />
              <InputField descriptor={descriptor} selectedDescriptors={selectedDescriptors} handleDescriptorsSelect={handleDescriptorsSelect} descriptorDependencies={descriptorDependencies} isLoading={triggerFiltersLoading} isSaving={isSaving} />
            </LoadingWrapper>
          </div>
        ))}
      </>
    )}
  </>
);