import { PossibleValue } from 'openapi/models/PossibleValue';
import { VALUE_ANY, VALUE_ANY_JENKINS } from 'utils/constants/common';

export type StringArrayDictionary = Record<string, string[]>;

export interface InputValue {
  id?: string;
  value?: PossibleValue;
}

export interface InitialValues {
  projects: string[];
  events: string[];
  threads: string[];
  descriptors: StringArrayDictionary;
}

export function isAnyValue(value: string) {
  return value === VALUE_ANY || value === VALUE_ANY_JENKINS;
}