import { InfoPopover } from 'components/InfoPopover/InfoPopover';

import './InfoPopoverLabel.css';

interface InfoPopoverLabelProps {
  readonly label: string;
  readonly surface: string;
  readonly id?: string;
  readonly className?: string;
  readonly children?: React.ReactNode;
}

export const InfoPopoverLabel = ({
  id,
  label,
  surface,
  className
}: InfoPopoverLabelProps) => {
  let labelClassName = 'info-popover-label';
  if (className) {
    labelClassName += ` ${className}`;
  }

  return (
    <label id={id} className={labelClassName}>
      <span>{label} </span>
      <InfoPopover surface={surface} />
    </label>
  );
};
