import { OpenAPI } from 'openapi';
import { toast } from 'react-toastify';
import { AuthenticationService } from 'services/Authentication';
import {
  ERROR_INTERNAL_SERVER_ERROR,
  ERROR_UNEXPECTED
} from 'utils/constants/errors';

const originalFetch = window.fetch;

window.fetch = async (
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<Response> => {
  try {
    if (AuthenticationService.isTokenExpired(OpenAPI.TOKEN as string)) {
      console.log('Token expired. Logging in again...');
      await AuthenticationService.login();
    }

    const response = await originalFetch(input, init);

    if (!response.ok) {
      const responseJson = await response.clone().json();
      let error = '';
      if (responseJson.error) {
        error = responseJson.error.detail;
      }

      handleError(error || response.status.toString());
    }

    return response;
  } catch (error) {
    handleError(ERROR_INTERNAL_SERVER_ERROR);
    throw error;
  }
};

const handleError = (errorMessage: string) => {
  console.error('Network or fetch error:', errorMessage);

  toast(errorMessage || ERROR_UNEXPECTED, {
    type: 'error',
    autoClose: false
  });
};

export {};
