import React from 'react';
import { Spinner } from '@fluentui/react-components';

import './LoadingWrapper.css';

interface LoadingWrapperProps {
  readonly isLoading: boolean;
  readonly children: React.ReactNode;
}

export const LoadingWrapper: React.FC<LoadingWrapperProps> = ({
  isLoading,
  children
}) => {
  return <>{children}
    <div className='spinner'>
      {isLoading && <Spinner size='extra-tiny'/>}
    </div>
  </>
    ;
};
