// src/hooks/useMessages.ts
import { useState, useEffect, useCallback } from 'react';
import { ChannelService, Message } from 'openapi'; // Ensure this import matches your actual API service

interface UseMessagesResult {
  messages: Message[];
  loading: boolean;
  error: Error | null;
}

export const useMessages = (channelId: string, messageSelect: (_: any, data: any) => void): UseMessagesResult => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchMessages = useCallback(async () => {
    if (!channelId) {
      return;
    }

    setLoading(true);
    try {
      const response = await ChannelService.getChannelMessages(channelId);
      let messages = response.data;
      setMessages(messages || []);

      if (messages?.length === 1) {
        // Select the first message
        const data = {
          selectedOptions: [
            messages[0].id
          ]
        };
        messageSelect(null, data);

      }
      setError(null);
    } catch (error) {
      setError(error as Error);
      setMessages([]);
    } finally {
      setLoading(false);
    }
  }, [channelId]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  return { messages, loading, error };
};
