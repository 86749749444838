import {
  Button,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Divider,
  Spinner,
  TableColumnId
} from '@fluentui/react-components';
import { useNavigate } from 'react-router-dom';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
  useMemo
} from 'react';
import { toast } from 'react-toastify';

import './WebhookListing.css';

import { Modal } from 'components/Modal/Modal';

import { SubscriptionService } from 'openapi';
import { SubscriptionView } from 'openapi/models/SubscriptionView';

import { TeamsFxContext } from 'utils/contexts/TeamsFxContext';
import { createWebhooksColumns } from 'utils/helpers/webhook-listing';
import { ROUTE_WEBHOOKS_CREATE } from 'utils/constants/routes';

export const WebhookListing = () => {
  const { context } = useContext(TeamsFxContext);

  const navigate = useNavigate();

  const [webhooks, setWebhooks] = useState<SubscriptionView[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Variables
  const idsForDeletion = useRef({
    subscriptionId: -1,
    projectId: -1
  });
  const channelId = useMemo(() => {
    return context?.channel?.id || '';
  }, [context]);

  // Handlers
  const navigateToUpdate = async (id: number, projectId: number) => {
    try {
      const subscriptionResponse = await SubscriptionService.getSubscription(
        channelId,
        projectId,
        id
      );

      if (subscriptionResponse.data) {
        navigate(ROUTE_WEBHOOKS_CREATE, { state: subscriptionResponse.data });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const navigateToCreate = () => {
    navigate(ROUTE_WEBHOOKS_CREATE);
  };

  const openModal = (subscriptionId: number, projectId: number) => {
    idsForDeletion.current = {
      subscriptionId,
      projectId
    };
    setIsModalOpen(true);
  };

  const closeModal = () => {
    idsForDeletion.current = {
      subscriptionId: -1,
      projectId: -1
    };
    setIsModalOpen(false);
  };

  const columns = createWebhooksColumns(navigateToUpdate, openModal, isDeleting);

  // Service calls
  const fetchData = useCallback(async () => {
    if (channelId) {
      try {
        const webhooksResponse =
          await SubscriptionService.getChannelSubscriptions(channelId);

        if (webhooksResponse.data) {
          setWebhooks(webhooksResponse.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [channelId]);

  const deleteWebhook = async () => {
    try {
      setIsDeleting(true);
      await SubscriptionService.deleteSubscription(
        channelId,
        idsForDeletion.current.projectId,
        idsForDeletion.current.subscriptionId
      );
      toast('Webhook is deleted successfully', { type: 'success' });
      fetchData();
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleting(false);
      closeModal();
    }
  };

  useEffect(() => {
    fetchData();
  }, [channelId]);

  return (
    <>
      <div className="heading-wrapper">
        <h1 className="page-heading">Webhooks</h1>
        <Button onClick={navigateToCreate} appearance="primary">
          Create new Webhook
        </Button>
      </div>
      <Divider />

      <Modal
        title="Confirmation"
        content="Are you sure you want to delete this Webhook?"
        isOpen={isModalOpen}
        isLoading={isDeleting}
        hideModal={() => setIsModalOpen(false)}
        handleConfirmation={deleteWebhook}
      />

      {webhooks.length > 0 ? (
        <DataGrid
          columns={columns}
          items={webhooks}
          sortable
          style={{ marginTop: '1rem' }}
        >
          <DataGridHeader>
            <DataGridRow>
              {({ renderHeaderCell, columnId }) => (
                <DataGridHeaderCell
                  key={columnId}
                  className={getClassNameByColumnId(columnId) + ' dg-header-cell'}>
                  {renderHeaderCell()}
                </DataGridHeaderCell>
              )}
            </DataGridRow>
          </DataGridHeader>
          <DataGridBody<any>>
            {({ item, rowId }) => (
              <DataGridRow<any> key={rowId}>
                {({ renderCell, columnId }) => (
                  <DataGridCell className={getClassNameByColumnId(columnId)}>
                    {renderCell(item)}
                  </DataGridCell>
                )}
              </DataGridRow>
            )}
          </DataGridBody>
        </DataGrid>
      ) : isLoading ? (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      ) : (
        <div className="no-data">No Webhooks created yet</div>
      )}
    </>
  );
};


function getClassNameByColumnId(columnId: TableColumnId) {
  switch (columnId) {
    case 'id':
      return 'id-cell';
    case 'projectName':
      return 'project-cell';
    case 'eventType':
      return 'event-cell';
    case 'messageContent':
      return 'thread-cell';
    case 'modifiedBy':
      return 'modifiedby-cell';
    case 'actions':
      return 'actions-cell';
    default:
      return ''; // Return an empty string or a default class as needed
  }
}