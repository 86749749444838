// usePlatforms.ts
import { useState, useEffect, useCallback } from 'react';
import { ProjectService, Platform } from 'openapi'; // Assuming 'openapi' provides the necessary types

interface UsePlatformsResult {
  platforms: Platform[];
  loading: boolean;
  error: Error | null;
}

export const usePlatforms = (channelId: string): UsePlatformsResult => {
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchPlatforms = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ProjectService.getTeamProjects(channelId);
      setPlatforms(response.data || []);
      setError(null);
    } catch (e) {
      setError(e as Error);
      setPlatforms([]);
    } finally {
      setLoading(false);
    }
  }, [channelId]);

  useEffect(() => {
    if (channelId) {
      fetchPlatforms();
    }
  }, [fetchPlatforms, channelId]);

  return { platforms, loading, error };
};
