// ThreadSelector.tsx
import React from 'react';
import { Dropdown, Option, Switch, Input } from '@fluentui/react-components';
import { Message } from 'openapi'; // Adjust if necessary based on your actual imports
import { LoadingWrapper } from 'components/LoadingWrapper/LoadingWrapper'; // Adjust the import path as necessary

interface ThreadSelectorProps {
  isThreadSelectEnabled: boolean;
  setIsThreadSelectEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  selectedThreads: string[];
  onThreadSelect: (event: any, data: any) => void;
  messages: Message[];
  threadInput: string;
  threadInputName: string;
  setThreadInput: React.Dispatch<React.SetStateAction<string>>;
  isEditMode: boolean;
  isLoading: boolean;
}

export const ThreadSelector: React.FC<ThreadSelectorProps> = ({
  isThreadSelectEnabled,
  setIsThreadSelectEnabled,
  selectedThreads,
  onThreadSelect,
  messages,
  threadInput,
  threadInputName,
  setThreadInput,
  isEditMode,
  isLoading
}) => {
  return (
    <>
      <div className="dropdown-wrapper">
        <div className="dropdown-label">
          <Switch
            checked={isThreadSelectEnabled}
            onChange={(event) => setIsThreadSelectEnabled(event.target.checked)}
            label="Select an existing thread*"
            labelPosition="before"
            className='thread-select-switch'
            disabled={isEditMode}
          />
        </div>

        <LoadingWrapper isLoading={isLoading && !isEditMode}>
          {isThreadSelectEnabled ? (
            <Dropdown
              aria-label="Select thread"
              appearance="underline"
              selectedOptions={selectedThreads}
              onOptionSelect={onThreadSelect}
              disabled={isEditMode || messages.length === 0}
              value={threadInputName}
            >
              {messages.map((message) => (
                <Option key={message.id} value={message.id?.toString()}>
                  {message.content}
                </Option>
              ))}
            </Dropdown>
          ) : (
            <Input
              value={threadInput}
              onChange={(_, data) => { if (data.value.length <= 30) { setThreadInput(data.value) } }}
              placeholder="Enter a new thread name"
              disabled={isEditMode}
              appearance="underline"
              className="thread-input"
            />
          )}
        </LoadingWrapper>
      </div>
    </>
  );
};
