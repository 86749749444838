// src/hooks/useInputValues.ts
import { InputDescriptor, PublisherInputs, SubscriptionService } from 'openapi'; // Adjust imports as necessary
import { useCallback, useEffect, useRef, useState } from 'react';
import { StringArrayDictionary, isAnyValue } from 'utils/interfaces/Common';

interface UseInputValuesParams {
    channelId: string;
    selectedProjectId: number | undefined;
    selectedEventId: string | undefined;
    selectedDescriptors: { [key: string]: string[] };
    handleDescriptorsSelect: (descriptorId: string) => (_: any, data: any) => void;
    isEventChanged: boolean;
}

interface UseInputValuesResult {
    inputDescriptors: InputDescriptor[];
    descriptorDependencies: StringArrayDictionary;
    loading: boolean;
    error: Error | null;
}

export const useInputValues = ({
    channelId,
    selectedProjectId,
    selectedEventId,
    selectedDescriptors,
    handleDescriptorsSelect,
    isEventChanged,
}: UseInputValuesParams): UseInputValuesResult => {
    const [inputDescriptors, setInputDescriptors] = useState<InputDescriptor[]>([]);
    const [descriptorDependencies, setDescriptorDependencies] = useState<StringArrayDictionary>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [isInitialRequest, setIsInitialRequest] = useState<boolean>(true);

    // Variables
    const previousSelectedDescriptors = useRef(selectedDescriptors);

    const getChangedDescriptors = () => {
        return Object.keys(selectedDescriptors).filter(
            (key) => selectedDescriptors[key] !== previousSelectedDescriptors.current[key]);
    }

    // return true if any descriptor is changed and it has dependencies
    const shouldResetDependencies = (changedDescriptors: string[]) => {
        for (let key in descriptorDependencies) {
            let dependencies = descriptorDependencies[key];
            if (dependencies.find((dependency) => changedDescriptors.includes(dependency))) {
                return true;
            }
        }
        return false;
    }

    const prefillDropdownsWithDefaultValues = (inputDescriptors: InputDescriptor[], changedDescriptors: string[]) => {
        inputDescriptors.forEach((desc) => {
            const hasDependency = desc.dependencyInputIds?.find((dependency) => changedDescriptors.includes(dependency));
            const selected = selectedDescriptors[desc.id!];
            if (selected && !hasDependency) { // if descriptor is already selected and it has no dependency
                return;
            }

            const anyValue = desc.values?.possibleValues?.find(pv => pv.displayValue === '[Any]');
            const defaultValue = desc.values?.defaultValue;
            console.log("prefilling for descriptor", desc.id, "anyValue: ", anyValue)

            if (anyValue) {
                handleDescriptorsSelect(desc.id as string)(null, {
                    selectedOptions: [anyValue.value]
                });
            } else if (defaultValue !== undefined) {
                handleDescriptorsSelect(desc.id as string)(null, {
                    selectedOptions: [defaultValue]
                });
            }
        });
    }

    const mapDependencies = (inputDescriptors: InputDescriptor[]) => {
        const dependencies: StringArrayDictionary = {};
        inputDescriptors.forEach((descriptor: InputDescriptor) => {
            if (descriptor.dependencyInputIds) {
                dependencies[descriptor.id!] = descriptor.dependencyInputIds;
            }
        });
        return dependencies;
    }

    const fetchInputValues = useCallback(async () => {
        if (!selectedProjectId || !selectedEventId) {
            setInputDescriptors([]);
            setDescriptorDependencies({});
            return;
        }

        const changedDescriptors = getChangedDescriptors();
        const dependenciesShouldBeReset = shouldResetDependencies(changedDescriptors);

        if (!isInitialRequest && Object.keys(selectedDescriptors).length > 0 && !dependenciesShouldBeReset) {
            return; //if independent descriptors are changed, no need to fetch input values
        }

        setLoading(true);
        try {
            const publisherInputs: PublisherInputs = Object.entries(selectedDescriptors).reduce(
                (acc, [key, value]) => ({ ...acc, [key]: value[0] }),
                {} as PublisherInputs
            );

            const response = await SubscriptionService.getInputValues(channelId, selectedProjectId, {
                eventId: selectedEventId,
                publisherInputs: isEventChanged ? undefined : publisherInputs,
            });

            if (response.data) {
                const inputDescriptors = response.data.inputDescriptors as InputDescriptor[];

                setInputDescriptors(inputDescriptors);
                prefillDropdownsWithDefaultValues(inputDescriptors, changedDescriptors);

                setDescriptorDependencies(mapDependencies(inputDescriptors));
                previousSelectedDescriptors.current = { ...selectedDescriptors }
            }

            setError(null);
        } catch (error) {
            setError(error as Error);
            setInputDescriptors([]);
            setDescriptorDependencies({});
        } finally {
            setLoading(false);
            setIsInitialRequest(false);
        }

    }, [channelId, selectedProjectId, selectedEventId, selectedDescriptors, isEventChanged]);

    useEffect(() => {
        fetchInputValues();
    }, [fetchInputValues]);

    return { inputDescriptors, descriptorDependencies, loading, error };
};