import React from 'react';
import { Dropdown, Option, OptionGroup } from '@fluentui/react-components';
import { InfoPopoverLabel } from 'components/InfoPopoverLabel/InfoPopoverLabel';
import { Platform } from 'openapi'; // Assuming Platform is the correct import based on your provided code
import { LoadingWrapper } from 'components/LoadingWrapper/LoadingWrapper';

interface ProjectDropdownProps {
  platforms: Platform[];
  selectedProject: string[];
  onProjectSelected: (event: any, data: any) => void; // Adjust types based on actual event and data types
  isEditMode: boolean;
  globalLoading: boolean;
  projectSelectedLoading: boolean;
  selectedProjectName: string;
}

export const ProjectDropdown: React.FC<ProjectDropdownProps> = ({
  platforms,
  selectedProject,
  onProjectSelected,
  isEditMode,
  globalLoading,
  projectSelectedLoading,
  selectedProjectName
}) => {
  return (
    <div className="dropdown-wrapper">
      <InfoPopoverLabel
        id="projects-dropdown"
        className="dropdown-label"
        label="Select a project*"
        surface="Select a project to subscribe to events for"
      />

      <LoadingWrapper isLoading={projectSelectedLoading}>
        <Dropdown
          aria-labelledby="projects-dropdown"
          appearance="underline"
          defaultSelectedOptions={selectedProject}
          selectedOptions={selectedProject}
          onOptionSelect={onProjectSelected}
          disabled={globalLoading || isEditMode || platforms.length === 0}
          value={selectedProjectName}
        >
          {platforms.map((platform: Platform) => (
            <OptionGroup
              key={platform.id}
              className="publisher-option-group"
              label={<InfoPopoverLabel label={platform.id as string} surface={platform.description as string} />}
            >
              {platform.projects && platform.projects.map((project) => (
                <Option key={project.id} value={project.id as unknown as string}>{project.name as string}</Option>
              ))}
            </OptionGroup>
          ))}
        </Dropdown>
      </LoadingWrapper>
    </div>
  );
};
