/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PlatformEnum {
    AZURE_DEVOPS = 'AzureDevops',
    JENKINS = 'Jenkins',
    BIT_BUCKET = 'BitBucket',
    GIT_HUB = 'GitHub',
}
